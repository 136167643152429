import { useSessionStorage } from '@vueuse/core'
import { useOverlays } from '~/composables/useOverlays'
import type { SbGlobalBanner } from '~/storyblok/types/storyblok'
import { useDataDogTest } from '~/composables/useDataDogTest'

export const useBanners = () => {
  const overlays = useOverlays('banner')
  const { isDataDogTest } = useDataDogTest()
  const { data, fetch } = overlays

  /**
   * Keep track of closed banners in session storage
   */
  const localKey = 'bannersLastCloseAt'
  const closed: any = import.meta.client
    ? useSessionStorage(localKey, {} as Record<string, string>)
    : undefined

  const shouldBeVisible = (
    banner: SbGlobalBanner & { published_at?: string },
    uuid: string,
  ): boolean => {
    if (isDataDogTest.value) {
      return false
    }

    if (import.meta.client && closed?.value) {
      const lastClosedAt = closed.value[uuid]
      if (banner.published_at && lastClosedAt) {
        return Date.parse(banner.published_at) > Number.parseInt(lastClosedAt)
      }
    }
    return true
  }

  const close = (uuid: string) => {
    if (import.meta.client && closed?.value) {
      closed.value[uuid] = Date.now().toString()
    }
  }

  return extendPromise(
    overlays.then(() => ({})),
    {
      banners: data,
      fetch,
      shouldBeVisible,
      close,
    },
  )
}
